/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import url('https://fonts.cdnfonts.com/css/gilroy-bold');

* {
    font-family: 'Gilroy' !important;
}

.router-wrapper {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: calc(100% - 47px);
    perspective: 1200px;
    transform-style: preserve-3d;
}

:host {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

.pl-0 {
    padding-left: 0;
}

.mt-32 {
    margin-top: 32px;
}

@media only screen and (max-width: 575.98px) {
    .page-wrapper .page-body-wrapper .page-body {
        padding-top: 93px !important;
    }

    .login-card .login-main {
        min-width: 340px !important;
    }
}

.page-body {
    margin-top: 0 !important;
    padding-top: 93px !important;
}

.btn {
    background: #6ED433;
    color: #FFFFFF;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;

    &.btn-primary.w-100 {
        width: 100%;
        padding: 16px;
    }

    &.btn-yellow{
        background: #FFB800 !important;
    }
    
    &.btn-danger{
        background: transparent;
        border:2px solid #FF0000 !important;
        background: #FFFFFF !important;
        color: #FF0000 !important;
    }
    
    &.btn-outline{
        background: transparent;
        border:2px solid #6ED433;
        color: #6ED433;
    }
}

td .btn{
    font-weight: 400;
    font-size: 14px;
}

.btns{
    display: flex;
    gap: 16px;
    width: fit-content;
}

.form-control {
    background: #F6F6F6 !important;
    border: 1px solid #E8E8E8 !important;
    color: #000000 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    width: 100% !important;
    padding: 16px !important;

    &::placeholder {
        font-weight: 500 !important;
        color: #BDBDBD !important;
    }
}